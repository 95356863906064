export function Error(props: { className: string }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.00275C0 12.421 3.58172 16.0027 8 16.0027C12.4183 16.0027 16 12.421 16 8.00275C16 3.58447 12.4183 0.00274658 8 0.00274658C3.58172 0.00274658 0 3.58447 0 8.00275ZM14 8.00275C14 11.3165 11.3137 14.0027 8 14.0027C4.68629 14.0027 2 11.3165 2 8.00275C2 4.68904 4.68629 2.00275 8 2.00275C11.3137 2.00275 14 4.68904 14 8.00275ZM6.86011 11.0307C6.86011 10.3587 7.35211 9.89075 8.00011 9.89075C8.64811 9.89075 9.14011 10.3587 9.14011 11.0307C9.14011 11.7027 8.64811 12.1707 8.00011 12.1707C7.35211 12.1707 6.86011 11.7027 6.86011 11.0307ZM7.57875 4H8.42125C8.86497 4 9.21168 4.38312 9.16753 4.82464L8.79503 8.54925C8.76947 8.80485 8.55438 8.9995 8.29751 8.9995H7.70249C7.44562 8.9995 7.23053 8.80485 7.20497 8.54925L6.83247 4.82464C6.78832 4.38312 7.13503 4 7.57875 4Z"
        fill="white"
      />
    </svg>
  );
}
